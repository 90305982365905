<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <kanban-board :stages="stages"
                              :blocks="blocks"
                              @update-block="updateBlock"
                ></kanban-board>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'CrmBoards',
    mixins: [commonTable],
    data() {
        return {
            stages: ['on-hold', 'in-progress', 'needs-review', 'approved','test1','test2','test3','test4','test5','test6','test7','test8','test9'],
            blocks: [
                {
                    id: 1,
                    status: 'on-hold',
                    title: 'Test',
                },
            ],
        }
    },
    methods: {
        updateBlock(id, status) {
            this.blocks.find(b => b.id === Number(id)).status = status;
        },
    },
}
</script>

<style>
.drag-container {
    max-width: none;
}
.drag-column{
    min-width: 200px;
}
</style>